@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700&display=swap);
#root {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

body,
* {
  line-height: 1.6 !important;
}

.item-icon {
  height: 12px !important;
  width: 12px !important  ;
}

.border-red {
  border: 1px solid #f44336 !important;
}

.border-primary {
  border: 1px solid #0bd19f !important;
}

.sticky-item {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
  padding: 10px;
}

.custom-icon-btn {
  border-radius: 3px !important;

  padding: 6px !important;
}

.dot-active {
  height: 20px;
  width: 20px;
  background-color: #0bd19f;
  border-radius: 50%;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.dot {
  height: 20px;
  width: 20px;
  background-color: #707070;
  border-radius: 50%;
  font-size: 12px;
  color: white;
  font-weight: bold;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.order-muted {
  font-size: 14px;
  color: #707070 !important;
}

.mt-4 {
  margin-top: 40px !important;
}

.my-3 {
  margin: 10px 0px !important;
}

.border-bottom-muted {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.text-muted-order {
  color: #969696 !important;
}

.fadeIn {
  -webkit-animation: fadeIn 300ms ease-in;
          animation: fadeIn 300ms ease-in;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fill {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.new-order {
  color: #cb6de2 !important;
  border: #f8e1fe !important;
  background: #f8e1fe !important;
}

.complete-order {
  background: #0bd19f33 !important;
  border: #0bd19f33 !important;
  color: #00d654 !important;
}

.green-custom {
  color: #0bd19f !important;
}

.accepted-order {
  background-color: #fff1bb !important;
  border: #fff1bb !important;
  color: #f8a529 !important;
}

.rejected-order {
  border: #f4b9b8 !important;
  background-color: #f4b9b8 !important;
  color: #e94645 !important;
}

.my-2 {
  margin: 5px 0px !important;
}

.my-3 {
  margin: 10px 0px !important;
}

.text-danger {
  color: #e94645 !important;
}

.new-order {
  border: #c3ecfd !important;
  background-color: #c3ecfd !important;
  color: #49c4fa !important;
}

.cancelled-order {
  border: #a0a0a076 !important;
  background-color: #a0a0a076 !important ;
  color: #888888 !important;
}

.delivery-in-progress-order {
  border: #e0792165 !important;
  background-color: #e0792165 !important ;
  color: #e07921 !important;
}

.finding-courier {
  border: #be65ff6a !important;
  background-color: #be65ff6a !important;
  color: #be65ff !important;
}
.p-3 {
  padding: 22px !important;
}

.chip-custom {
  border-radius: 5px !important;
  height: 37px !important;
}

.max-width-chip {
  max-width: 190px !important;
}

.list-column-title {
  color: #888888 !important;
}

.border-green {
  border: 1px solid #0bd19f;
  border-radius: 3px;
}

.p-2 {
  padding: 10px;
}

.px-2 {
  padding: 10px 0px;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.input-label {
  font-weight: 600 !important;
  color: black !important;
}

.font-20 {
  font-size: 20px !important;
}

.bg-gray-300 {
  background-color: #f0f2f2;
}

.cursor-pointer {
  cursor: pointer;
}

.order-info {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.title {
  font-size: 22px !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

.ml-1 {
  margin-left: 5px !important;
}

.ml-2 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.mr-2 {
  margin-right: 10px !important;
}

.text-decoration-none {
  text-decoration: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.pac-container:after {
  content: none !important;
}

.text-muted-100 {
  color: #bdbdbd !important;
}
.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.mb-4 {
  margin-bottom: 40px !important;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px !important;
}

.py-1 {
  padding: 5px 0px;
}

.py-2 {
  padding: 10px 0px !important;
}

.text-capitalize {
  text-transform: uppercase !important;
}

.text-center {
  text-align: center;
}

.text-white {
  color: #fff;
}

.login-bg {
  padding: 80px;
  background-color: #0bd19f;
}

.h-100 {
  height: 100% !important;
  min-height: 100% !important;
}

.w-100 {
  width: 65% !important;
}

.spacing {
  letter-spacing: 1.5px;
}

.text-dark {
  color: black !important;
}

.text-muted {
  color: #545454;
}

.mt-2 {
  margin-top: 10px !important;
}

.mt-3 {
  margin-top: 20px;
}

.green {
  color: rgb(29, 198, 152) !important;
  font-weight: 900;
}

.menuItem-img {
  height: 64px;
  width: 64px;
  border-radius: 2px;
  object-fit: cover;
}

.small {
  font-size: 80%;
}

/* css to remove google map credits */

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.small {
  font-size: 80%;
}

.text-muted {
  color: #6c757d !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.pac-container:after {
  content: none !important;
}
.sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 64px !important;
  left: 0px;
  right: 0px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0px 1px 0 rgb(31 38 135 / 37%);
  -webkit-backdrop-filter: blur(16.5px);
          backdrop-filter: blur(16.5px);
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.p-1 {
  padding: 5px;
}

.pb-2 {
  padding: 7px 0px !important;
}

.fw-normal {
  font-weight: 800 !important;
}

.color-black {
  color: black !important;
}

.text-danger {
  color: rgb(219, 23, 23);
}

.mt-3 {
  margin-top: 20px !important;
}

.bg-white {
  background-color: #fff;
}

.mb-3 {
  margin-bottom: 20px !important;
}

.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  padding-right: 0 !important;
}

